import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import { setAuthenticated, onLoad, setPreviousPath, setChannelBackPath, toggleLoading, setError, setNow, clearStreamVod, setModal } from './actions';
import { loginCheck, refreshToken, loginProfileLoad, guideRefresh } from '../redux-root/actions';
import usePrevious from '../utils/usePrevious';
import gtmHelper from '../utils/gtmHelper';

import styles from './index.module.css';

import NavigationMenu from '../components/Menu';
import NavigationMobileMenu from '../components/NavigationMobileMenu';
const LoginPage = loadable(() => import("../page/Login"));
const HomePage = loadable(() => import('../page/Home'));
const SearchPage = loadable(() => import('../page/Search'));
const DetailsPage = loadable(() => import('../page/Details'));
const OnDemandPage = loadable(() => import('../page/OnDemand'));
const FavouritePage = loadable(() => import("../page/Favourite"));
const SubscriptionsPage = loadable(() => import("../page/Subscriptions"));
const SubscriptionsDetailPage = loadable(() => import("../page/SubscriptionsDetail"));
const WatchHistoryPage = loadable(() => import("../page/WatchHistory"));
const LoadingPage = loadable(() => import('../page/Loading'));
const ErrorPage = loadable(() => import('../page/Error'));
const AnimationPage = loadable(() => import('../page/Animation'));
const VodPlayer = loadable(() => import('../page/Player/Vod'));
const LivePlayer = loadable(() => import('../page/Player/Live'));
const AboutUsPage = loadable(() => import("../page/AboutUs"));
const AccountDetailPage = loadable(() => import("../page/AccountDetail"));
const ParentalControlPage = loadable(() => import("../page/Parental"));
const Modal = loadable(() => import('../components/Modal'));
const GuidePage = loadable(() => import('../page/Guide'));
const SetupPage = loadable(() => import("../page/Setup"));
const LinkPage = loadable(() => import("../page/Link"));
const TermsPage = loadable(() => import("../page/Terms"));
const DRPage = loadable(() => import('../page/DR'));

let refreshInterval;
let gtmObj = {};

const isTouchScreen = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
const refreshTokenBool = localStorage.getItem("_r");

function App(props) {
  const { authenticated, initialLoad, loading, error, animation, menuCollapsed, streamVod, modal, channelData, profileLoaded, profileData, skipStep, showTerms, hashUserId, drMode, config, tvMode } = props;

  const [preLoading, setPreLoading] = useState(false);
  const [scrollHidden, setScrollHidden] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);
  const [minMenu, setMinMenu] = useState(false);
  const [backStatus, setBackStatus] = useState(false);

  const navigateTo = useNavigate();
  const location = useLocation();
  const previousAuth = usePrevious(authenticated);
  const previousInitialLoad = usePrevious(initialLoad);
  const previousProfileLoaded = usePrevious(profileLoaded);
  const previousLocation = usePrevious(location);
  const prevStream = usePrevious(streamVod);

  useEffect(() => {
    if (window.tizen && window.tizen.tvinputdevice) {
      window.tizen.tvinputdevice.registerKey('MediaPlay');
      window.tizen.tvinputdevice.registerKey('MediaPause');
      window.tizen.tvinputdevice.registerKey('ChannelUp');
      window.tizen.tvinputdevice.registerKey('ChannelDown');
    }

    props.onLoad();

    if (!refreshTokenBool) {
      navigateTo('/login');
    } else if (
      location.pathname === '/index.html' ||
      location.pathname === '/login' ||
      location.pathname === '/setup' ||
      location.pathname === '/link' ||
      location.pathname === '/terms'
    ) {
      navigateTo('/');
    }

    window.onpopstate = () => {
      // window.location.reload(false); // DISABLE REFRESH

      if (!localStorage.getItem("_r")) {
        navigateTo('/login');
      } else if (
        location.pathname === '/index.html' ||
        location.pathname === '/login' ||
        location.pathname === '/setup' ||
        location.pathname === '/link' ||
        location.pathname === '/terms'
      ) {
        navigateTo('/');
      }

      setBackStatus(true);
      props.setModal(null);
      props.clearStreamVod();
    }

    window.addEventListener('online', () => networkChange(true));
    window.addEventListener('offline', () => networkChange(false));

    return () => {
      if (window.tizen && window.tizen.tvinputdevice) {
        window.tizen.tvinputdevice.unregisterKey('MediaPlay');
        window.tizen.tvinputdevice.unregisterKey('MediaPause');
        window.tizen.tvinputdevice.unregisterKey('ChannelUp');
        window.tizen.tvinputdevice.unregisterKey('ChannelDown');
      }

      window.removeEventListener('online', () => networkChange(true));
      window.removeEventListener('offline', () => networkChange(false));
    }
    /* eslint-disable */
  }, []);

  useEffect(() => {
    if (previousAuth !== authenticated && authenticated) {
      if (location.pathname === "/login" || location.pathname === '/setup') {
        navigateTo('/');
      }

      setPreLoading(true);

      if (initialLoad) {
        props.loginProfileLoad(channelData.freeChannel);

        if (!tvMode) {
          document.addEventListener('visibilitychange', checkVisibility);
        }
      }
    }

    return () => {
      if (!tvMode) {
        document.removeEventListener('visibilitychange', checkVisibility);
      }
    }
    /* eslint-disable */
  }, [authenticated]);

  useEffect(() => {
    if (previousInitialLoad !== initialLoad && initialLoad) {
      refreshInterval = setInterval(() => {
        const newNow = new Date();

        if (newNow.getHours() === 0 && newNow.getMinutes() === 0) {
          props.guideRefresh();
        }

        props.setNow(newNow);
      }, 60000);

      if (refreshTokenBool) {
        setPreLoading(true);

        Auth.currentSession()
          .then(ses => {
            // console.log('Cognito Token refreshed');

            props.loginCheck(ses.accessToken.jwtToken, true);
            props.setAuthenticated(true);
          })
          .catch(err => {
            console.log(err);

            navigateTo('/login'); // TV ONLY (MAYBE)
            Auth.signOut();
            setPreLoading(false);

            localStorage.removeItem('_r');
          });
      } else if (authenticated) {
        props.loginProfileLoad(channelData.freeChannel);
      }
    }

    return () => {
      clearInterval(refreshInterval);
    }
    /* eslint-disable */
  }, [initialLoad]);

  useEffect(() => {
    if (previousProfileLoaded !== profileLoaded && profileLoaded) {
      setPreLoading(false); // JUST TO LET OTHER PAGES LOAD AFTER LOGIN CHECK

      if (!tvMode && !skipStep) {
        navigateTo('/link');
      } else if (showTerms) {
        navigateTo('/terms');
      }
    }
    /* eslint-disable */
  }, [profileLoaded]);

  useEffect(() => {
    if (!menuCollapsed || tvMode) {
      setScrollHidden(true);
    } else {
      setScrollHidden(false);
    }
    /* eslint-disable */
  }, [menuCollapsed]);

  useEffect(() => {
    if (previousLocation !== undefined && previousLocation.pathname !== location.pathname) {
      setRefreshLoading(true);

      if (location.pathname.includes('/movie') || location.pathname.includes('/show')) {
        props.setPreviousPath(previousLocation.pathname);
      } else {
        props.setPreviousPath(null);
      }

      if (location.pathname.includes('/channel') && !previousLocation.pathname.includes('/channel')) {
        props.setChannelBackPath(previousLocation.pathname);
      } else if (previousLocation.pathname.includes('/channel') && !location.pathname.includes('/channel')) {
        props.setChannelBackPath(null);
      }

      if (authenticated) {
        Auth.currentSession()
          .then(ses => {
            props.refreshToken(ses.accessToken.jwtToken);
            setRefreshLoading(false);
          })
          .catch(err => {
            Auth.signOut();
            props.setAuthenticated(false);
            navigateTo('/login'); // TV ONLY (MAYBE)

            localStorage.removeItem('_r');
            setRefreshLoading(false);
          })
      } else {
        setTimeout(() => {
          setRefreshLoading(false);
        }, 500);
      }
    }
    /* eslint-disable */
  }, [location]);

  useEffect(() => {
    if (prevStream !== undefined && prevStream !== streamVod) {
      setStreamLoading(true);

      if (authenticated) {
        Auth.currentSession()
          .then(ses => {
            props.refreshToken(ses.accessToken.jwtToken);
            setStreamLoading(false);
          })
          .catch(err => {
            Auth.signOut();
            props.setAuthenticated(false);
            navigateTo('/login'); // TV ONLY (MAYBE)

            localStorage.removeItem('_r');
            setStreamLoading(false);
          })
      } else {
        setTimeout(() => {
          setStreamLoading(false);
        }, 500);
      }
    }
    /* eslint-disable */
  }, [streamVod]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setMinMenu(true);
    }
  /* eslint-disable */
  }, [minMenu]);

  useEffect(() => {
    if (backStatus) {
      props.setError(null);

      setTimeout(() => {
        setBackStatus(false);
      }, 200);
    }
  /* eslint-disable */
  }, [backStatus, error]);

  useEffect(() => {
    if (drMode) {
      navigateTo('/');
    }
  /* eslint-disable */
  }, [drMode]);

  const logoutClick = () => {
    props.toggleLoading();

    gtmObj.category = 'watchcast-logout';
    gtmObj.userId = hashUserId;

    gtmHelper({
      ...gtmObj,
      section: 'authentication',
      subSection: 'are-you-sure-you-want-to-logout?',
      action: 'button-click',
      label: 'yes',
      componentType: 'logout-pop-up'
    });

    Auth.signOut();
    props.setAuthenticated(false);
    navigateTo('/login');
  }

  const checkVisibility = () => {
    const loadDate = new Date(localStorage.getItem('_et'));
    const nowDate = new Date();

    if (!document.hidden) {
      if (nowDate.getDate() !== loadDate.getDate()) {
        props.guideRefresh();
      }
    }
  }

  const networkChange = (status) => {
    if (status) {
      console.log('online');
    } else {
      console.log('offline');
    }
  }

  let showMenu = false;

  if (location.pathname !== '/login' && location.pathname !== '/setup' && location.pathname !== '/link' && location.pathname !== '/terms') {
    showMenu = true;
  }

  return (
    <div className={styles['wrapper']}>
      {
        drMode ?
          <div className={styles['page']}>
            <DRPage />
          </div>
          :
          !preLoading && !refreshLoading ?
            <div className={styles['page-wrapper']}>
              {
                showMenu && !loading ?
                  !minMenu ?
                    <NavigationMenu />
                    :
                    <NavigationMobileMenu logoutClick={logoutClick} />
                  :
                  ""
              }

              <div className={`${styles['page']} ${location.pathname === '/login' ? styles['login-mode'] : ""} ${scrollHidden ? styles['scroll-hidden'] : ""} ${!showMenu && isTouchScreen ? styles['mobile-full'] : ""}`} id="app-page">
                {
                  initialLoad ?
                    <Routes>
                      <Route path="/login" element={<LoginPage fallback={<LoadingPage />} />} />

                      <Route path="/setup" element={<SetupPage fallback={<LoadingPage />} />} />
                      <Route path="/link" element={<LinkPage fallback={<LoadingPage />} />} />
                      <Route path="/terms" element={<TermsPage fallback={<LoadingPage />} />} />

                      <Route path="/" element={<HomePage fallback={<LoadingPage />} />} />
                      <Route path="/search" element={<SearchPage fallback={<LoadingPage />} />} />
                      <Route path="/ondemand" element={<OnDemandPage fallback={<LoadingPage />} />} />
                      <Route path="/guide" element={<GuidePage fallback={<LoadingPage />} />} />

                      <Route path="/favourite" element={<FavouritePage logoutClick={logoutClick} />} />
                      <Route path="/subscriptions" element={<SubscriptionsPage logoutClick={logoutClick} />} />
                      <Route path="/subscriptions/:id" element={<SubscriptionsDetailPage logoutClick={logoutClick} />} />
                      <Route path="/watch-history" element={<WatchHistoryPage logoutClick={logoutClick} />} />
                      <Route path="/about-us" element={<AboutUsPage logoutClick={logoutClick} />} />
                      <Route path="/account-details" element={<AccountDetailPage logoutClick={logoutClick} />} />
                      <Route path="/parental-control" element={<ParentalControlPage logoutClick={logoutClick} />} />

                      <Route path="/show/:id" element={<DetailsPage />} />
                      <Route path="/movie/:id" element={<DetailsPage />} />
                      <Route path="/channel/:id" element={<LivePlayer fallback={<LoadingPage />} />} />

                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    :
                    config ?
                      <Routes>
                        <Route path="/login" element={<LoginPage fallback={<LoadingPage />} />} />
                        <Route path="/setup" element={<SetupPage fallback={<LoadingPage />} />} />
                      </Routes>
                      :
                      ""
                }

              </div>

              {
                streamVod && !streamLoading ?
                  <VodPlayer />
                  :
                  ""
              }
            </div>
            :
            ""
      }

      {
        modal ?
          <Modal />
          :
          ""
      }

      {loading || refreshLoading || (!initialLoad && !drMode && (location.pathname !== '/login' && location.pathname !== '/setup')) ? <LoadingPage /> : ""}

      {
        animation ?
          <AnimationPage />
          : ""
      }

      {
        error ?
          <ErrorPage />
          : ""
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  authenticated: state.app.authenticated,
  initialLoad: state.app.initialLoad,
  loading: state.app.loading,
  error: state.app.error,
  animation: state.app.animation,
  modal: state.app.modal,
  menuCollapsed: state.menu.menuCollapsed,
  streamVod: state.app.streamVod,
  channelData: state.app.channelData,
  profileLoaded: state.app.profileLoaded,
  profileData: state.app.profileData,
  skipStep: state.app.skipStep,
  showTerms: state.app.showTerms,
  hashUserId: state.app.hashUserId,
  drMode: state.app.drMode,
  drLoading: state.app.drLoading,
  config: state.app.config,
  tvMode: state.app.tvMode
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuthenticated,
      onLoad,
      loginCheck,
      refreshToken,
      setPreviousPath,
      setChannelBackPath,
      toggleLoading,
      loginProfileLoad,
      setError,
      guideRefresh,
      setNow,
      clearStreamVod,
      setModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
